.blocks{
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1400px) {
    .blocks{
        max-width: 1320px;
    }  
}

@media (min-width: 1850px) {
    .blocks{
        max-width: 1740px;
    }  
}

.body-box{
    background-color: #2C2C39;
    border-radius: 1rem;
    padding: var(--bs-gutter-x, 1rem);
    padding-left: var(--bs-gutter-x, 1.2rem);
}

.body-box a{
    color: #ff8562;
}