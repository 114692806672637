a {
    color: white;
    text-decoration: none;
}

.modem-info{
    font-size: 24px;
    
}

.modem-info a{
    color: #ff8562;
}

.navbar-dark .navbar-nav .nav-link{
    color: white;
}

.mission-download .text a{
    color: #ff8562;
}

.heading{
    font-size: 42px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.heading-small{
    font-size: 26px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gs-button-info{
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
}

.gs-button-info> div > a > img{
    margin-top: 50px;
    width: 100px;
}

.gs-button-info> div > a{
    color: white;
}

.client-support{
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    font-size: 24px;
}

.ogn-rtk{
    margin-top: 100px;
    margin-left: 20%;
    margin-right: 20%;
}

.ogn-rtk-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    width: 100%;
    margin-bottom: 80px;
    align-items: center;
}

.ogn-rtk-container img{
    width: 100%;
}

.specifications{
    margin-left: 20%;
    margin-right: 20%;
}

.specifications p {
    margin-bottom: 10px;
}

.introduction{
    place-items: center;
    text-align: center;
}

.introduction img{
    width: 20%;
}

.contacts{
    padding-top: 10vw;
    padding-bottom: 10vw;
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
}
/*#root > div > div.blocks{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}*/

/*.body-box-home:has( > div){
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}*/

.desktop {
    display: block;
}
  
.mobile {
    display: none;
}

@media (max-width: 768px) {
    .mobile {
      display: block;
    }

    .desktop {
        display: none;
    }
}



.body-box-home{
    background-color: #1F1E25;
    padding-left: 0px;
    padding-right: 0px;
}

.label-text{
    position: absolute;
    top:43%;
    left: 15%;
}

.label-text-big{
    font-size: 58px;
    font-weight: bold;
}

.label-text-mid{
    font-size: 26px;
    font-weight: bold;
}

.label-text-small{
    padding-top: 5px;
    font-size: 16px;
    width: 100%;
}

.buy-button{
    margin-top: 52px;
    margin-left: 0px;
    background: linear-gradient(90deg, #A635FF 12.14%, #5EAAFF 62.94%, #7C35FF 94.27%);
    border: 0px;
    height: 52px;
    width: 323px;
    left: 0px;
    top: 0px;
    border-radius: 33px;
    vertical-align: middle;
    text-align: center;
}

.buy-button > div {
    padding-top: 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    vertical-align: middle;
}

@media (max-width: 1050px) {
    .label-text-mid{
        font-size: 20px;
    }

    .label-text-small{
        font-size: 14px;
        width: 80%;
    }

    .label-text{
        position: absolute;
        top:35%;
        left: 8%;
    }

    .label-img{
        padding-bottom: 60px;
    }

    .modem-info-block{
        margin-left: 8%;
        margin-right: 8%;
    }

    .modem-info-block{
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .modem-info-block{
        font-size: 18px;
    }

    .ogn-rtk-container{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        width: 100%;
        margin-bottom: 80px;
        align-items: center;
    }

    .label-text{
        position: absolute;
        top:85vw;
        left: 5%;
    }
    
    .label-text-big{
        font-size: 36px;
        font-weight: bold;
    }
    
    .label-text-mid{
        font-size: 16px;
        font-weight: bold;
    }
    
    .label-text-small{
        font-size: 12px;
        width: 95%;
    }

    .buy-button{
        margin-top: 52px;
        margin-left: auto;
        margin-right: auto;
        background: linear-gradient(90deg, #A635FF 12.14%, #5EAAFF 62.94%, #7C35FF 94.27%);
        border: 0px;
        height: 52px;
        /*width: 323px;*/
        width: 95%;
        left: 0px;
        top: 0px;
        border-radius: 33px;
        vertical-align: middle;
        text-align: center;
    }
}
