.is_online{
    color: green;
}

.is_offline{
    color: red;
}

.update_icon {
    width: 25px;
    margin-left: auto;
    margin-right: 0;
}

.drones{
    display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 26px;
  height: 40px;
  color: hsl(0, 0%, 100%);
}

.nebosvod_info{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 450px;
    grid-gap: 10px;
    align-items: center;
}

.ong_info{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50px 150px 150px 150px;
    grid-gap: 10px;
    align-items: center;
}

.modem_settings_row {
    display: flex;
    flex-wrap: wrap;
}

.modem_settings_col_4 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
}
.modem_settings_col_8 {
    flex: 0 0 66.6%;
    max-width: 66.6%;
}
.modem_settings_col_12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.text-gl-muted {
    color: #89888d;
}
.m-form-group {
    margin-bottom: 1rem;
}

.input_settings {
    line-height: 1;
    margin-top: 1rem;
}

.dropdown_settings {
    width: 100%;
    
}

.upgrade_steps p{
    margin-bottom: 0.6rem;
}

#dropdown-settings {
    width: 100%;
    line-height: 1;
}

.dropdown-menu-t-w{
    width: 100%;
}

@media (max-width: 1000px) {
    .modem_settings_col_4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .modem_settings_col_8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .drones{
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }

    .nebosvod_info{
        grid-template-columns: 450px;
    }

}

@media (max-width: 400px){
    .nebosvod_info{
        grid-template-columns: 280px;
    }

    .ong_info{
        grid-template-columns: 280px;
    }
}



.modem-card{
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid #dee2e600;
}

.modem-card p{
    margin-bottom: 0.5rem;
}

.active-modem-card{
    border: 1px solid #dee2e6;
}

.bind-card{
    background-color: #1f1e25;
    border: 1px solid #2C2C39;
    font-size: 30px;
    color: #2C2C39;
    cursor: pointer;
}
.bind-card > p{
    margin-left: auto;
    margin-right: auto;
    width: 1em;
    margin-bottom: 0px;
}

.sort-card {
    margin-bottom: 15px;
    border: 1px solid #dee2e600;
    cursor: pointer;
}

.sort-card p{
    margin-bottom: 0px;
}

.modem-version-name {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.modem-verion-text{
    text-align: right;
}

.bind-spinner-info{
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 26px;
  align-items: center;
}

.baud_rate{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 82px 150px;
    grid-gap: 26px;
    align-items: center;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ff8562;
    background-color: #2C2C39;
}

.nav-tabs .nav-link{
    color:rgb(255, 255, 255);
}

.nav-tabs .nav-link:hover{
    color:#ff8562;
}

.bind-buttons {
    display: flex;
    gap: 10px;
}