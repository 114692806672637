@font-face {
    font-family: "DeadSpace-Regular";   /*Can be any text*/
    src: local("DeadSpace-Regular"),
      url("./DeadSpace-Regular.ttf") format("truetype");
}

.yellcs {
  margin-left: 20%;
  margin-right: 20%;
}

.yellcs{
  font-family: "DeadSpace-Regular";
}

img{
  width: 100%;
}