.nav-buttons{
    display: grid;
    grid-template-columns: 100px 100px 100px;
    margin-bottom: 20px;
}

.logs-info{
    display: grid;
    grid-template-columns: 200px 100px;
    margin-bottom: 20px;
}