.scan-region-highlight-svg {
    stroke: white !important;
}

#qrScannerVideoId {
    max-width: 100%;
}

.actionsContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
