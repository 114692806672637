
.wiki a{
    margin-bottom: 1rem;
}

.wiki{
    font-size: 20px;
}

.wiki h1{
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 40px;
}

.wiki h2{
    font-size: 26px;
    margin-top: 30px;
    margin-bottom: 20px;

}

.wiki p{
    font-size: 16px;
}

.wiki-grid{
    display: grid; 
    grid-template-columns: 0.7fr 5fr;
}

.wiki-content{
    margin-left: 5%;
    margin-right: 5%;
    
}

.wiki-nav{
    padding: "1rem";
    border-right: solid 1px;
}

.wiki_img img{
    width: 70%;
}

@media (max-width: 768px) {
    .wiki-grid{
      grid-template-columns: 1fr;
    }

    .wiki-nav{
        padding: 0px;
        border-bottom: solid 1px;
        border-right: 0px;
    }
    .wiki-content{
        margin-left: 0px;
        margin-right: 0px;
        
    }
    .wiki_img img{
        width: 100%;
    }

}

.wiki-video{
    margin-top: 40px;

}

.wiki-nav a{
    font-size: 16px;
    color: rgb(255, 255, 255);
}

.wiki-nav a:hover{
    color: #ff8562;
}

.thumb-wrap {
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
}
.thumb-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
}