.mission-download{
    display: grid;
    grid-template-columns: 10% 5fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    width: 100%;
    margin-bottom: 80px;
    margin: 20px;
    align-items: top;
}

.mission-download .btn-success{
    width: 80%;
}

.mission-download img{
    width: 100%;
}

.mission-download .heading{
    margin-top: 0px;
}

.download-buttons a{
    color: #ffffff;
}

@media (max-width: 768px) {
    .mission-download{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        column-gap: 20px;
        width: 100%;
        margin-bottom: 80px;
        margin: 0px;
        align-items: top;
        
    }

    .mission-download img{
        width: 50%;
        padding-left: auto;
        padding-right: auto;
    }

    .mp-logo{
        place-items: center;
        width: 100%;
    }

    .mission-download .btn-success{
        width: 100%;
    }
}